@media (min-width:0px) and (max-width:767px) {
    .mobile_history {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .mobile_filt button.accordion-button {
        padding: 11px 1.25rem;
    }

    .date_filter.mobile_history h5 {
        font-weight: 450;
        font-size: 18px;
        line-height: 23px;
    }

    ul.mobile_record_list li h6.price1,
    ul.list_appoint_mob li .price1 {
        // background: rgba(239, 98, 134, 0.2);
        background: rgba(59, 153, 163, 0.2);
        border-radius: 5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        // color: #EF6286;
        color: #3B9915;
        display: inline-block;
        padding: 0px 4px;
    }

    
    .mobile_record .priceWeb {
        // background: rgba(239, 98, 134, 0.2);
        background: #fce0e7;
        border-radius: 5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        // color: #EF6286;
        color: #EF6286;
        display: inline-block;
        padding: 0px 4px;
    }

}