body{
    background-color: #fff;
}
.tutorial-banner{
    background-color: #E1F3F4;
    border-radius: 36px;
}
.pt-150{
    padding-top: 150px;
}
.ff-Nunito{
    font-family: 'Nunito', sans-serif;
}
.underline_ancer {
    font-size: .938rem;
    font-weight: 700;
    letter-spacing: .094rem;
    max-width: max-content;
    position: relative;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    width: 100%;
    color: #313131;
    display: inline-block;
}
.underline_ancer:hover {
    color: #313131;
}
.underline_ancer::after {
    background: none repeat scroll 0 0 transparent;
    background-color: #313131;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    max-height: 0.5px;
    position: absolute;
    transition: width .3s ease 0s,left .3s ease 0s;
    width: 0;
}
.underline_ancer:hover::after {
    width: 100%;
    left: -1px;
}