.reg-bg-color {
  background-color: #c3e7e8;

  background-image: url(../../assets/images/png/thankyou-bg.png);

  background-repeat: no-repeat;

  background-size: cover;

  background-position: center;

  width: 100%;

  height: 100%;
}

.text-red {
  color: red !important;
}

.h-100-vh {
  height: 100vh;
}

.iconItem svg {
  height: 40px
}

.opacity-1 {
  opacity: 1;
}

.fw-700 {
  font-weight: 700;
}

img.circle {
  width: 30px;

  height: 30px;
}

.text-left {
  text-align: left;
}

/* ------------------------------------ */

.registeration {
  background-color: #c3e7e8;
}

.LogoImage {
  max-width: 300px;
}

.otp-screen {
  background-image: url(../../assets/images/png/backgroundImage.png);
}

.heading-first {
  padding-top: 50px;
}

.heading-first h1 {
  font-size: 40px;

  font-weight: bold;

  color: #313131;

  font-family: Nunito;
}

.registeration-form .border-red .MuiInputBase-root,
.registeration-form .border-red.MuiInputBase-root,
.registeration-form .border-red.form-control {
  border-color: red !important;
}

.border-purple {
  border-color: purple !important;
}

.bottom-border {
  margin-top: 10px;

  /* border: 2px solid #1ab5b7; */

  width: 80%;
}

.description {
  padding-top: 26px;

  font-size: 26px;

  color: #404040;
}

.main-list {
  display: block;

  padding-top: 25px;
}

.group1 p {
  padding-left: 26px;

  font-size: 20px;
}

.group2 p {
  padding-left: 26px;

  font-size: 20px;
}

.fs-20 {
  font-size: 20px;
}

.group3 p {
  padding-left: 26px;

  /* padding-top: 10px; */

  font-size: 20px;
}

.main-list .group1 {
  display: flex;

  padding-top: 45px;
}

.main-list .group2 {
  display: flex;

  padding-top: 34px;
}

.main-list .group3 {
  display: flex;

  padding-top: 34px;
}

.registeration-form {
  background-color: #ffffff;
  transition: all 0.4s ease;
  border-radius: 40px;

  /* margin-top: -55px; */
}

.form-control {
  border-radius: 25px;

  box-shadow: 0 8px 22px 0 rgb(0 0 0 / 24%), 0 5px 37px -29px rgb(0 0 0 / 19%);
}

.form-control h1 {
  font-size: 34px;
}

.opt_box {
  border-radius: 20px;

  /* background-color: var(--white-clr); */

  padding: 20px;

  display: flex;

  align-items: center;

  justify-content: center;
}

.input-cont {
  padding-top: 30px;
}

.opt_input {
  width: 10%;

  background-color: var(--white-clr);

  /* border: 16x solid #E8E8E8; */

  padding: 15px;

  width: 100px;

  height: 100px;

  font-size: 50px;

  border-radius: 10px;

  text-align: center;
}

.input_box {
  border-radius: 10px;

  background-color: var(--white-clr);

  min-height: 52px;

  font-family: "Inter", sans-serif;

  padding-top: 10px;

  display: block;
}

.custom_input {
  width: 100%;

  background-color: var(--white-clr);

  border: 1px solid #e8e8e8;

  padding: 10px;

  border-radius: 15px;
}

.registeration-form .MuiInputBase-root {
  background-color: var(--white-clr);

  border: 1px solid #e8e8e8;

  border-radius: 15px !important;
}

.custom_Number_input {
  width: 100%;

  background-color: var(--white-clr);

  border: 1px solid #e8e8e8;

  padding: 10px;

  border-radius: 15px;

  margin-left: 20px !important;
}

.customTextarea {
  width: 100%;

  min-height: 100px;

  border-radius: 10px;

  background-color: #f7f7f7;
}

.btn-group {
  background-color: #48ab06;
  width: 100%;
  padding: 10px;
  justify-content: center;
  border-radius: 15px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-weight: 600;
  transition-duration: 0.4s;
  box-shadow: 0 8px 22px 0 rgb(0 0 0 / 24%), 0 5px 37px -29px rgb(0 0 0 / 19%);
  letter-spacing: 1px;
}

/* ------------------------------------------------------- */

/* .Mui-selected {
  background-color: #ee6285 !important;

  color: #fff !important;
  z-index: 98;
}

.MuiTabs-fixed {
  text-align: center;
}

.MuiTabs-flexContainer {
  text-align: center;

  width: auto;

  display: inline-flex !important;

  border-radius: 50px;

  background-color: rgb(236, 236, 236);

  align-items: center;

  justify-content: center;
}

.MuiTabPanel-root {
  padding: 0px !important;
  transition: width 600ms ease-out, height 600ms ease-out;
} */

/* ----------------------------------------------------- */

.btn-group:hover {
  background-color: #ee6285;
}

.confirm-button {
  padding-top: 20px;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 10px 5px;

  /* padding-bottom: 24px; */

  border-radius: 15px;

  border: 1px solid #e8e8e8;
}

.opacity-1 {
  opacity: 1;
}

.thankYou {
  font-size: 40px;

  line-height: 1.3;
}

.sms-otp {
  font-size: 20px;

  padding-top: 20px;
}

.checkbox {
  transform: scale(1.5);

  accent-color: #ee6285;
}

.agree {
  padding-left: 1rem;
}

.CheckIfAgree {
  padding: 1rem 0 0 0.7rem;
}

.agree a {
  color: #ee6285;

  text-decoration: underline;
}

.forgotPassword a {
  color: #ee6285;

  text-decoration: underline;
}

.NotAMember {
  padding: 2rem 0 0 0.4rem;
}

.NotAMember label {
  /* padding: 0 0 0 1rem; */
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;

}

.MuiTabs-flexContainer {
  display: flex;

  justify-content: center;
}

.PrivateTabIndicator-colorSecondary-3 {
  display: none;
}

.PrivateTabIndicator-colorSecondary-7 {
  display: none;
}

.react-tel-input .flag-dropdown {
  padding: 8px;

  margin-left: 4px;

  border-radius: 10px;
}

.react-tel-input .flag-dropdown.open {
  border-radius: 15px;

  background-color: #f5f5f5;
}

.react-tel-input .selected-flag:hover {
  background-color: #f5f5f5;
}

.react-tel-input .form-control {
  min-height: 53px;

  padding: 16.5px 14px;

  width: 86% !important;

  border-radius: 15px;

  box-shadow: none;

  margin-left: 5rem;
}

.AppNumber::placeholder {
  color: #404040;

  text-align: center;

  font-size: 26px;

  font-weight: 700;
}

@media screen and (min-width: 992px) {
  .px-lg-8 {
    padding-right: 8rem !important;

    padding-left: 8rem !important;
  }
}

@media screen and (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
}

@media screen and (max-width: 1024px) {
  .react-tel-input .form-control {
    width: 81%;
  }
}

@media screen and (max-width: 1200px) {
  .h-100-vh {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {

  h1.thank-you,
  h1.thankYou,
  h1.reg-head,
  .form-control h1 {
    font-size: 25px;

    line-height: 35px;
  }

  .bottom-border {
    width: 100%;
  }

  .react-tel-input .form-control {
    width: 74%;
  }

  .md-order-2 {
    order: 2;
  }

  .reg-bg-color,
  .otp-screen {
    background-image: none;

    text-align: center;
  }

  .opt_input {
    width: 60px;

    height: 60px;
  }

  .heading-first h1 {
    font-size: 25px;
  }

  .NotAMember {
    text-align: left;
  }
}

@media (max-width: 575px) and (min-width: 320px) {

  .container,
  .container-sm {
    max-width: 300px;
  }

  .react-tel-input .form-control {
    width: 66% !important;
  }

  .bottom-border {
    width: 100%;
  }

  .column {
    padding-top: 20px;
  }

  .agree {
    padding-top: 0 !important;
  }

  .group3 p {
    font-size: 14px;
  }

  .group2 p {
    font-size: 14px;
  }

  .group1 p {
    font-size: 14px;
  }

  .fs-20 {
    font-size: 14px;
  }
}

/* .registeration-form .css-1aquho2-MuiTabs-indicator{
  background-color: transparent;
} */

.registeration-form .MuiTabs-indicator {
  background-color: transparent !important;
}

.css-ttwr4n {
  background-color: transparent !important;
}

.css-ttwr4n {
  background-color: transparent !important;
}

.registeration-form .input_box,
.OtpCounting span {
  display: block;
}

.registeration-form .input_box span {
  padding-left: 0;
}

.OtpCounting span {
  order: 2;
  
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #E9406A;

}

.OtpCounting button {
  order: 1;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 5px;
  color: #313131;

}

.MuiPopover-root .MuiPaper-rounded {
  border-radius: 20px;
}