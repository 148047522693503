.hk_doctor_login .description-circle-group img {
  height: 26px;
}

.singup-mobile-col.hk_doctor_login .image-below img {
  height: 240px;
}


.logo_only img {
  height: 40px;
}

.hk_doctor_login .continue-number-box p {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 20px;
}


  /* Your CSS for word-spacing property */
  /* .hk_doctor_login .continue-number-box .instant-error-msg{
    word-spacing: -4px;
  } */

.hk_doctor_login .hk_number.ek_number {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  position: relative;
}

.hk_doctor_login .hk_number .country_code_hk {
  border-right: 1px solid #ccc;
  padding: 0 0 0 5px;
}

.hk_doctor_login .btn-cont .instant-btn {
  border-radius: 12px;
  background-color: #19b3b5;
  color: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 53px;
  padding: 0;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
}

.hk_doctor_login .instant_login_hk button.statenotchanged span.instant-chevron.instant-code-chev {
  background: #8E8E8E !important;
  color: #fff;
  opacity: 0.5;
}

.hk_doctor_login .btn-cont .instant-btn .instant-chevron {
  float: right;
  background-color: #078a8e !important;
  font-size: 16px;
  height: 44px;
  width: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  right: 0;
  left: auto;
}

.hk_doctor_login .instant_login_hk button.statenotchanged {
  background: #e5ece8 !important;
  color: #959494 !important;
}

p.instant-error-msg {
  color: #bc0001 !important;
  font-weight: 500 !important;
  font-family: 'Circular Std';
  font-size: 12px;
  position: relative;
  top: 7px;
}




.hk_doctor_login .hk_number .ant-select.select-country.hk_network .ant-select-selector span.ant-select-selection-item img {
  margin-right: 5px;
}

.hk_doctor_login span.instant-chevron.instant-code-chev svg {
  font-size: 22px;
  position: relative;
  /* top: 14px; */
}

.singup-mobile-col.hk_doctor_login .continue-number-box .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
  border-bottom: none;
  border-top: none;
  border-right: none;
}

.singup-mobile-col.hk_doctor_login .hk_number input.input-number {
  border-bottom: none;
  border-top: none;
  border-radius: 12px;
  color: #313131;
}

.singup-mobile-col.hk_doctor_login .hk_number .country_code_hk .ant-select-selector {
  border: none;
}

.singup-mobile-col.hk_doctor_login .label_and_loader_area img {
  height: 30px;
}

.singup-mobile-col.hk_doctor_login .label_and_loader_area {
  position: absolute;
  right: -3px;
  margin-top: -25px;
}

/* for chrome */
  .singup-mobile-col.hk_doctor_login p.userNotFoundError.instant-error-msg {
    font-size: 14px;
    color: #19B3B5 !important;
    word-spacing: -4px;

  }
/* for chrome */

/* for other browsers */

  .singup-mobile-col.hk_doctor_login p.userNotFoundErrorTwo.instant-error-msg {
    font-size: 14px;
    color: #19B3B5 !important;
  }

/* for other browsers */


.singup-mobile-col.hk_doctor_login .form_controll.form-drop.form-Econtrol {
  position: relative;
}

.create-appointment-modal input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
}

.create-appointment-modal input::-webkit-outer-spin-button,
.create-appointment-modal input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 767px) {


  .singup-mobile-col.hk_doctor_login p.userNotFoundError.instant-error-msg {
    font-size: 16px;
    color: #bc0001 !important;
    word-spacing: -4px;
    text-align: left;
    position: relative;
    /* left: 28px; */
    font-weight: 500 !important;
    font-family: 'Circular Std';

  }

  .hk_bottom_stick {
    margin-top: 80%;
  }


    .singup-mobile-col.hk_doctor_login p.userNotFoundErrorTwo.instant-error-msg {
      font-size: 16px;
      color: #bc0001 !important;
      /* word-spacing: -1px; */
      text-align: left;
      position: relative;
      /* left: 28px; */
      font-weight: 500 !important;
      font-family: 'Circular Std';
  
    }
}