.headingWithSpaceLarge {
  /* color: #29bcc1; */
  color: black;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-family: "Nunito", sans-serif;
}
