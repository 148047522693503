.rtx_video_main {
  width: 100%;
  height: 100%;
  position: relative;

  .rtx_video_container {
    width: 100%;
    height: 100%;
  }

  .rtx_video_player {
    width: 100%;
    height: 100%;
    // display: none;

    // &:first-child {
    //   display: block;
    // }
    // &:nth-child(2) {
    //   display:block;
    // }

    video {
      object-fit: cover !important;
    }
  }

  .rtx_video_small_frame {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 145px;
    width: 250px;
    z-index: 10;
    border-radius: 10px;
    overflow: hidden;
  }

  .controls {
    background-color: #00000061;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    z-index: 10;

    .video_btn {
      background-color: var(--text-dark-clr);
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 22px;
      margin: 0 5px;
      border-radius: 30px;
      cursor: pointer;
      transition: 0.3s;

      &.off {
        background-color: #ff0000;
      }

      &:hover {
        background-color: #ff0000;
      }

      &:last-child {
        background-color: #ff0000;
        display: none;

        &:hover {
          background-color: var(--red-clr);
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .rtx_video_main {
    .rtx_video_container {
      .rtx_video_small_frame {
        width: 120px;
        height: 160px;
      }

      .rtx_video_player {
        video {
          object-fit: cover !important;
        }
      }
    }
  }
}