.sign-upnew .notificationToast {
    top: 0;
}

.sign-upnew {
    z-index: 99999;
}

.btn-okay {
    border-radius: 12px;
    background-color: #19b3b5;
    color: white;
    height: 48px;
    padding: 0;
    width: 100%;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #ffffff;
}

.mt-70 {
    margin-top: 70px;
}

.bookedAreaBooking .card {
    margin-top: 40px !important;
}



.borderstyle {
    position: relative;
}

.borderstyle:before {
    content: '';
    width: 1px;
    height: 36%;
    position: absolute;
    left: 14px;
    bottom: 2px;
    border: 0.75px dashed #D9D9D9;
}

.borderstyle:nth-child(3):before {
    bottom: 0px;
}

.sign-upnew .notificationToast .control_lang {
    display: none;
}

a.activeclass {
    border-bottom: 5px solid #19B3B5;
    margin-bottom: 0px !important;
}

.toast_blue.width_toast .Toastify__toast-container.Toastify__toast-container--top-right {
    min-width: 440px;
    width: 440px;
}

.zeind {
    z-index: 9;
}

section#navbar .navbar-light .navbar-nav>a:last-child h5 {
    /* color: #EF6286 !important; */
}

.main_step_box .ant-picker.ant-picker-status-success.no_icon_date span.ant-picker-suffix {
    background: initial;
    opacity: 0;
}

button.last {
    text-align: right;
}

.ant-select-item.ant-select-item-option:hover .ant-select-item-option-content {
    /* color: #fff !important; */
}

.my_profile {
    margin-right: 18px;
}

.my_profile a {
    border-radius: 8px;
    border: 0.8px solid #51C8CD;
    padding: 8px 11.8px 7.4px 12px;
    color: #51C8CD;
    text-align: center;
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
}

.my_profile p {
    border-radius: 8px;
    border: 0.8px solid #51C8CD;
    padding: 8px 11.8px 7.4px 12px;
    color: #51C8CD;
    text-align: center;
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

.checkbox_blue {}

.checkbox_blue label {
    /* padding: 6px 9px; */
    border: 1px solid #19B3B5;
    margin-left: 0 !important;
    margin-right: 8px;
}

.checkbox_blue label span.ant-checkbox {
    padding-bottom: 8px;
    padding-left: 7px;
}

.checkbox_blue label span.days {
    padding-left: 0;
}

.add_more.position-relative.r-1.mobile-r1 {
    justify-content: start;
    margin-bottom: 30px;
}

.titlinText {
    padding-left: 34px;
}

.btn_add.t-nt:hover,
.btn_add.t-nt:focus {
    background: #fff;
}

.ant-modal-mask {
    background-color: #000000c7 !important;
}

.dashboardNew .percentageBar a.activebtn {
    background: #51C8CD;
    color: #fff;
    border-color: #fff;
}

.ul_dots {
    list-style: none;
}

.ul_dots li {
    position: relative;
    margin-bottom: 12px;
    padding-left: 27px;
    padding-top: 1px;
}


.ul_dots li::before {
    width: 16px;
    height: 16px;
    content: "\2022";
    color: #29BCC1; 
    font-size: 28px; 
    position: absolute; 
    top: -2px; 
    left: 0;
}


img.w-5 {
    width: 20px;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected .ant-select-item-option-content {
    color: #fff !important;
}


.anticon svg {
    fill: #31313100;
}
.doctor-error-msg p { 
    word-spacing: -3px;
    margin-top: 7px;}
    .cursor-auto {
        cursor: auto!important;
        background: initial;
    }
    
    .cursor-auto .ant-select-selector {
        background: initial!important;
    }


    .select-code {
        cursor: pointer !important;
    }
.number__code input{width: 71px;
    height: 48px;
    border: none;
    outline: none;
    color: #706F6F;
    text-align: left;
    font-family: "Circular Std";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 140%;
    letter-spacing: 0.2px;
    padding-left: 5px;
    background: initial;}
    .number__code {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6' fill='none'%3E%3Cpath d='M4.79217 4.85292C4.73038 4.93082 4.65179 4.99375 4.56227 5.03701C4.47275 5.08026 4.37461 5.10273 4.27519 5.10273C4.17577 5.10273 4.07763 5.08026 3.98811 5.03701C3.89858 4.99375 3.82 4.93082 3.75821 4.85292L0.843068 1.45545C0.703207 1.2858 0.628032 1.07205 0.630903 0.852196C0.616049 0.642484 0.684505 0.435377 0.821413 0.275828C0.95832 0.116279 1.15263 0.017165 1.36217 0L7.18963 0C7.28816 0.00207172 7.38508 0.0254594 7.47371 0.0685547C7.56234 0.11165 7.64059 0.17343 7.70307 0.249647C7.84075 0.419742 7.91587 0.631946 7.91587 0.850781C7.91587 1.06962 7.84075 1.28182 7.70307 1.45192L4.78934 4.84655L4.79217 4.85292Z' fill='%23706F6F'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 51px center;
    }
    
    input.formSearch {
        width: 475px;
        height: 44px;
        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid #959494;
        background: #FFF;
        color: #959494;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding-left: 35px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 16 15' fill='none'%3E%3Cpath d='M13.8032 12.6583L10.2087 9.19294C11.0724 8.19325 11.5031 6.91101 11.4112 5.61295C11.3193 4.3149 10.7119 3.10099 9.71524 2.22373C8.71862 1.34647 7.40956 0.873421 6.06038 0.902979C4.71119 0.932537 3.42577 1.46243 2.47151 2.38243C1.51725 3.30242 0.967622 4.54169 0.936963 5.84243C0.906304 7.14316 1.39698 8.40522 2.3069 9.36606C3.21683 10.3269 4.47596 10.9125 5.82236 11.0011C7.16875 11.0898 8.49875 10.6745 9.53568 9.84182L13.1301 13.3072L13.8032 12.6583ZM1.90436 5.9654C1.90436 5.14861 2.15558 4.35016 2.62627 3.67103C3.09695 2.99189 3.76596 2.46257 4.54868 2.15C5.3314 1.83742 6.19268 1.75564 7.02361 1.91499C7.85454 2.07434 8.6178 2.46766 9.21687 3.04522C9.81594 3.62277 10.2239 4.35863 10.3892 5.15972C10.5545 5.96082 10.4696 6.79117 10.1454 7.54579C9.82122 8.3004 9.27218 8.94538 8.56775 9.39917C7.86332 9.85295 7.03514 10.0952 6.18793 10.0952C5.05224 10.0939 3.96343 9.65846 3.16038 8.88424C2.35733 8.11002 1.90562 7.06031 1.90436 5.9654Z' fill='%23959494'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center left 9px;
        box-shadow: initial;
    }
    
    .daterange {
        padding-left: 30px;
    }
     
    .daterange .date_filter .ant-picker.ant-picker-range .ant-picker-input {
        width: 147.27px;
        height: 43px;
        border: 1px solid #959494;
    }
    
    .daterange .date_filter .ant-picker-range-separator:after {
        display: none;
    }
    .dashboard  p.empty-state.text-danger {
        color: #959494!important;
        text-align: center;
        font-family: "Circular Std";
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding: 120px 0;
    }
         
    .create-appointment-modal .modal-dialog {
        max-width: 619px!important;
    }
    
    .create-appointment-modal .modal-dialog .modal-content {
        padding: 0 38px 40px 38px;
    }
    .create-appointment-modal .form-group {
        margin-bottom: 15px;
    }
    .create-appointment-modal .modal-dialog .modal-content button.btn-close {
        position: absolute;
        right: 40px;
        top: 30px;
        width: 16px;
        height: 16px;
        z-index: 9999;
        /* padding:
         0; */
    }
    input.formSearch:focus {
        box-shadow: initial;
        border-color: #959494;
    }
    /* .ant-picker-dropdown {
        z-index: 999999 !important;
    } */
    .create-appointment-text {
        color: #404040;
        font-family: "Circular Std";
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    
    .create-appointment-modal .modal-dialog .modal-content label {
        color: #313131;
        font-family: "Circular Std";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    
    .create-appointment-modal .form-area input,.create-appointment-modal .form-area select {
        color: #959494;
        font-family: "Circular Std";
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal; 
    }
    .boxPaitentBlue {
        zoom: 87%;
        border-radius: 8px;
        background: #19B3B5;
        padding: 17px 32px;
        color: #fff;
    }
    
    .boxPaitentBlue p {
        color: #FFF;
        font-family: "Circular Std";
        font-size: 20px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }
    
    .boxPaitentBlue button {
        color: #FFF;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.9px;
        text-decoration-line: underline;
        display: inline-flex;
    }
    
    .boxPaitentBlue button img {
        margin-right: 12px;
    }
    
    span.infoIcon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='26' viewBox='0 0 25 26' fill='none'%3E%3Cpath d='M12.3429 0.657135C5.52962 0.657135 0 6.18674 0 13C0 19.8132 5.52962 25.3428 12.3429 25.3428C19.1562 25.3428 24.6858 19.8132 24.6858 13C24.6858 6.18674 19.1562 0.657135 12.3429 0.657135ZM13.5772 19.1714H11.1086V16.7028H13.5772V19.1714ZM13.5772 14.2343H11.1086V6.82856H13.5772V14.2343Z' fill='white'/%3E%3C/svg%3E");
        width: 24px;
        height: 24px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 10px;
    }
    .create-appointment-modal  button.submit-btn-completed {
        min-width: 100%!important;
        height: 58px!important;
    }
    
    .create-appointment-modal button.submit-btn-completed span.add-record-chevron {
        height: 100%;
        width: 58px;
    }
    
    .create-appointment-modal {
        zoom: 83%;
        background: rgb(0 0 0 / 32%);
        backdrop-filter: blur(5px);
    }
 
    .datePickerModal .ant-picker {
        box-shadow: initial;
        border: 0;
        padding: 0;
        width: 100%;
    }
    
    .datePickerModal .ant-picker input {
        margin: 0;
        width: 100%;
    }
     
    
    .datePickerModal .ant-picker span.ant-picker-suffix {
        display: none;
    }

.timePicker input,.selectIcon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'%3E%3Cpath d='M1 1L5.29496 5.83183C5.37452 5.92134 5.51437 5.92134 5.59393 5.83183L9.88889 1' stroke='%23959494' stroke-width='1.2' stroke-linecap='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right 15px;
}
button.followUp {
    border-radius: 6px;
    border: 0.3px solid #313131;
    background: #19B3B5;
    display: flex;
    width: 175px;
    padding: 8px 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.12px;
    text-transform: uppercase;
    margin-left: 20px;
}

.pastConsult.rightSideTable .MuiButtonBase-root.MuiTab-root {
    width: auto;
}

.pastConsult .instant-feedback-table thead tr:first-child th {
    background: #F6F6F6!important;
    color: #313131!important;
}
.pastConsult.rightSideTable span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {display:none;}
.pastConsult .instant-feedback-table tbody tr td:first-child {
    font-size: 13px;
}

.pastConsult .instant-feedback-table tbody tr td:nth-child(6) {
    font-size: 14px;
    font-family: "Circular Std";
    font-weight: 450;
}

.pastConsult .instant-feedback-table tbody tr td:nth-child(2) span, .pastConsult .instant-feedback-table tbody tr td:nth-child(3)  span, .pastConsult .instant-feedback-table tbody tr td:nth-child(4)  span, .pastConsult .instant-feedback-table tbody tr td:nth-child(5)  span {
    color: #313131;
    font-family: "Circular Std";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* display: inline-block; */
    height: 24.43px;
    line-height: 24.43px;
}


.tableHistory tbody tr td:first-child p {
    color: #313131;
    font-family: "Circular Std";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.tableHistory tbody tr td:first-child span {
    color: #848484;
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px;
}
 

.tableHistory tbody tr td:nth-child(2) p,.tableHistory tbody tr td:nth-child(3) p, .tableHistory tbody tr td:nth-child(4) p, .tableHistory tbody tr td:nth-child(5) p, .tableHistory tbody tr td:nth-child(6) p, .tableHistory tbody tr td:nth-child(7) p {
    font-family: "Circular Std";
    font-weight: 300;
}

.tableHistory tbody tr td:nth-child(2) p strong ,.tableHistory tbody tr td:nth-child(3) p strong,.tableHistory tbody tr td:nth-child(5) p strong,.tableHistory tbody tr td:nth-child(6) p strong{
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
}
button.stress {
    color: #313131;
    text-align: center;
    font-family: "Circular Std";
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.bllodgroupText {
    font-size: 20px!important;
    font-weight: 300!important;
    height: 54px;
    line-height: 54px;
    padding: 0 23px;
 
}

.bloodGroupUser {
    font-family: "Circular Std";
    font-size: 16px;
    font-weight: 300;
}

textarea.bloodGroupUser {
    background: #fff;
    color: #404040;
    resize: none;
}

.leftBoxUserDetail label {
    color: #313131;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.pastConsult .MuiBox-root {
    justify-content: space-between;
    width: 100%;
}

.pastConsult .MuiBox-root .MuiTabs-root.css-1ujnqem-MuiTabs-root {
    width: 100%;
}
.previous-appointments-link img {
    transform: rotate(180deg);
}
.formSearch.appointmentSearch {
    width: 260px;
}
.flex_center.boxTimeQue {
    align-items: self-start;
}

.flex_center.boxTimeQue h6.headingWithSpaceLarge {
    font-family: "Circular Std";
    font-size: 18px!important;
    font-weight: 450;
    line-height: normal;
}

.flex_center.boxTimeQue h3 {
    font-size: 32px;
}
.forCustomP {
    position: relative;
    top: -7px;
}
.cancelHeading{font-size: 28px;}
.leaveConsultationModal button { font-weight: 700;text-transform: uppercase;}
.confirmLogoutModdal button.btn-lg,.confirmLogoutModdal .btn-btn-lg {
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    width: 168px!important;
    height: 48px;
    /* margin: 0!important; */
}
.tableDetail .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    justify-content: start;
}
.tags {
    display: inline-block;
    text-align: center;
    padding: 4px 16px;
    min-width: 110px;
    min-height: 34px;
    line-height: 34px;
}

span.heigh.tags {
    border-radius: 8px;
    border: 0.5px solid #F34E30;
    background: #F34E30;
    color:#fff;
    text-align: center;
    font-family: "Circular Std";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

span.normal.tags {
    border-radius: 8px;
    border: 0.5px solid #2A87FE;
    color: #2A87FE;
    text-align: center;
    font-family: "Circular Std";
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}
.textVital {
    color: #313131;
    text-align: center;
    font-family: "Circular Std";
    font-size: 32px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.textVital span {
    color: #313131;
    font-family: "Circular Std";
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.date {
    color: #313131;
    font-family: "Circular Std";
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.time {
    color: #848484;
    font-family: "Circular Std";
    font-size: 22px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}
.appointments_wrapper.cover_space a.consult_now.consult_now_instant.justify-content-start .inline_data.btn.btn-theme {
    width: 240px;
    min-width: 177px!important;
}
.bt-1 {
    border-top: 1px solid #d7d7dd;
}

@media (min-width:0px) and (max-width:767px) {

    .number__code input {
        width: 50px   ;
         
        font-size: 14px;}
        .number__code { 
            background-position: 34px center;
        }
    .selectSpeciality .ant-select {
        border-radius: 5px !important;
    }

    .selectSpeciality .ant-select {

        padding: 4px 0;
    }

    .dashboardNew .percentageBar a {
        margin: 0px auto 0;
    }

    .mobile_menu .m-auto.nav a:nth-child(2) {
        display: none;
    }

    .checkbox_style.consultation_checkbox .video_consult {
        margin-top: 18px;
    }

    .toast_sucsess01 .Toastify .Toastify__toast-container .Toastify__toast--success {
        min-height: auto;
        border-radius: 6px;
        border: 0.5px solid #19B3B5;
        background: #FFF;
        padding: 0;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        top: 10px;
    }

    .toast_sucsess01 .Toastify .Toastify__toast-container .Toastify__toast--success button.Toastify__close-button.Toastify__close-button--light {
        display: none;
    }

    .toast_sucsess01 .Toastify .Toastify__toast-container .Toastify__toast--success .Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--success {
        opacity: 0 !important;
    }

    .toast_sucsess01 .Toastify .Toastify__toast-container .Toastify__toast--success .Toastify__toast-icon {
        display: none;
    }

    .toast_sucsess01 .Toastify .Toastify__toast-container .Toastify__toast--success p {
        color: #19B3B5;
        text-align: center;
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 2px;
    }

    section.dashboardNew {
        padding-top: 10px !important;
    }

    .profileLabels {
        margin-top: 0 !important;
        margin-left: 5px !important;
    }

    .checkbox_blue label {
        height: auto;
        padding-right: 10px;
    }

    .checkbox_blue label span.days {
        padding-right: 10px;
    }

    .checkbox_blue label span {
        line-height: 17px !important;
    }

    section#whychooseus .offset-md-1 img {
        width: auto;
    }

    section#whychooseus .offset-md-1 img {
        height: auto;
    }

    .toast_blue.width_toast .Toastify__toast-container.Toastify__toast-container--top-right {
        min-width: 280px;
        width: 280px;
    }

    .text-end.pos_mobile {
        text-align: left !important;
        margin-bottom: 10px;
    }

    section.footer.position-relative {
        padding: 50px 15px;
    }

    .signup_otp_style .doctor-error-msg {
        margin-left: 24px;
        width: 266px !important;
    }

    .signup_otp_style .input-otp-error {
        max-width: 266px;
        margin: 0 auto;
    }

    .mob-100 {
        width: 100% !important;
    }

    section#whychooseus .me-3 {
        margin-right: 10px !important;
    }

    .box_chose {
        max-width: 270px;
    }

    .mob_size {
        width: 40px;
    }

    .singleBoxArea {
        max-width: 312px;
        margin: 0 auto 25px auto;
    }

    section#testimonials h2.heading3 {
        width: 344px;
        margin: 0 auto;
    }

    section#registration .registerBtns,
    section#featured .registerBtns {
        width: 263px;
        margin: 0 auto;
    }

    #registration h3 {
        width: 300px;
        margin: 0 auto;
    }

    .sideBar .complate_profile h6 {
        margin-left: 1px !important;
        padding-right: 10px;
    }

    .open_sidebar.sidebarsignup {
        padding: 0 15px 0px !important;
    }

    .sign-upnew {
        display: none;
    }

    .signup_otp {
        background: #C3E7E8;
        min-height: inherit;
        height: 100%;
        max-height: 100%;
    }

    .signup_otp_style .otp-login-box {
        padding: 0 !important;
    }

    .signup_otp_style h3 {
        font-size: 20px !important;
        line-height: 27px !important;
        justify-content: center;
    }

    .signup_otp_style p {}

    .signup_otp_style .otp-login-box input {
        width: 44px !important;
        height: 44px !important;
        border: 0.5px solid #031313;
        border-radius: 5px !important;
    }



    .signup_otp_style .confirm-paragraph p {
        font-size: 14px;
        line-height: 20px;
    }

    .signup_otp_style .resend-sms-otp.px-4.mt-5 {
        margin-top: 40px !important;
    }












    .signup_otp_style .resend-sms-otp.px-4.mt-5 {
        margin-top: 40px !important;
    }



    .signup_otp_style .loginOtp-phone-btn {
        background: #1FA7A8 !important;
    }

    .signup_otp_style .Otp-continue-btn {
        margin: 0 auto !important;
        min-width: 325px;
    }

    .signup_otp_style .Otp-continue-btn span.loginOtp-phone-chevron {
        float: initial !important;
        position: initial !important;
        padding: 0 !important;
        width: auto !important;
        background: #1FA7A8 !important;
    }

    .signup_otp_style .Otp-continue-btn {
        padding-top: 30px;
        margin-left: 60px;
        padding-bottom: 20px;
    }

    .signup_otp_style .Otp-continue-btn {
        padding-top: 139px;
    }

    .signup_otp_style .OtpCounting button {
        font-size: 14px;
        line-height: 18px;
        margin: 0;
    }

    .signup_otp_style {
        margin: 0 auto;
    }

    .signupDetails .white-form h2 {

        font-size: 13px !important;
        line-height: 18px !important;
    }

    .signupDetails .specialityHeightFix {

        border-radius: 5px !important;
        padding: 10px;
    }

    .signupDetails .white-form .detail-form {

        border-radius: 5px !important;

    }

    .signupDetails .p-4 {
        padding: 1.5rem 18px !important;
    }















    .signupDetails .signup_otp_style {
        margin: 0 auto;
    }


    .signupDetails .Otp-continue-btn {
        width: 170px !important;
        margin: 44px auto 0 auto !important;
    }

    .signupDetails .Otp-continue-btn span.signupDetails-phone-chevron {
        display: none !important;
    }

    .signupDetails .Otp-continue-btn button.review-button {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        border-radius: 10px;
    }

    .signupDetails div#demo-multiple-name {
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        color: #959494;
        padding: 0 0;
    }

    .signupDetails .details-select {
        font-size: 13px;
        border: 0.5px solid #959494 !important;
        border-radius: 5px !important;
        color: #959494;
    }

    .signupDetails p.termsParaa {
        display: none;
    }

    .thankyou-time-booked {
        font-size: 24px !important;
        line-height: 33px !important;
        margin-top: 22px !important;
    }

    .timeBookedSlot {
        margin-bottom: 22px !important;
        margin-top: 20px !important;
        font-size: 16px !important;
        line-height: 22px !important;
    }

    .thankyouscreen .bookedAreaBooking .bg-black {
        width: 100% !important;
        margin: 1rem auto !important;
    }

    .para-application {
        font-family: "Circular Std";
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
    }

    .pinkBookedColor {
        font-weight: 700 !important;
        font-size: 18px !important;
        line-height: 25px !important;
        height: 38px;
        border-radius: 8px !important;
    }



    .bookedAreaBooking .card.mt-5 {
        background: rgb(255 255 255 / 80%) !important;
        margin-top: 29px !important;
        border-radius: 12px;
        border: 0px;
        padding: 21px 16px !important;
    }

    .bookedAreaBooking .card h5 {
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 25px !important;
    }

    .bookedAreaBooking .card h6 {
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
    }

    .borderstyle:nth-child(3):before {
        bottom: -4px;
    }


    .mobile_style_thankyou {
        padding: 33px 20px !important;
    }

    .mobile_style_thankyou img {
        width: 40px;
        height: 40px;
    }

    .mobile_style_thankyou .thankyou-heading {
        font-weight: 500;
        font-size: 20px !important;
        line-height: 27px !important;
    }

    .mobile_style_thankyou p.text-uppercase.para-application.my-4 {
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 16px !important;
        margin-top: 20px !important;
    }

    .mobile_style_thankyou .application-number {
        font-size: 18px !important;
        border-radius: 8px !important;
        line-height: 38px;
        height: 38px;
    }

    .mobile_style_thankyou .ContactText {
        font-size: 13px !important;
        line-height: 16px !important;
    }

    .mobile_style_thankyou p.saveText {
        font-size: 13px !important;
        line-height: 19px !important;
    }

    .select-availabilty-btn {
        border-radius: 12px !important;
        height: 48px !important;
        line-height: 48px !important;
        font-size: 14px !important;
    }
}

@media (min-width:0px) and (max-width:360px) {

    section#testimonials h2.heading3 {
        width: 300px;
        margin: 0 auto;
    }

    .signup_otp_style .Otp-continue-btn {
        max-width: 240px !important;
        min-width: 240px !important;
    }
}
