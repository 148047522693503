.noClinics {
  .simple_ancer_small {
    background-color: var(--green-clr);
    justify-content: center;
    margin-top: 20px;
    justify-content: space-between;
    flex-direction: row-reverse;
    max-width: 392px;
  }
  .btnRow {
    justify-content: flex-start;
  }
}
.form_end {
  margin-top: 10px;
  .col-lg-3 {
    align-self: center;
  }
}
.online_time {

  .column_flex {
    height: 75vh;
    .confirm_btn {
      width: 100%;
      padding: 30px 0;
    }
  }
  .ant-form {
    .column_flex {
      .row {
        padding-top: 20px;
      }
    }
    .ant-form-item {
      width: 100%;
    }
  }
  .confirm_btn {
    padding: 0;
    .simple_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--green-clr);
      text-transform: uppercase;
    }
  }
  .labelText {
    color: #404040;
  }
  .heading_desc_small {
    font-weight: 700;
    padding: 20px 0 10px;
  }

  .addSlots {
    .simple_btn {
      width: max-content;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 15px;
      padding-left: 0;
      font-size: 0.875rem;
      letter-spacing: normal;
      text-transform: none;
      padding: 20px 0;
      color: #ef6286;
      .back_arrow {
        font-size: 2rem;
      }
    }
  }
}

.clinics {
  padding-bottom: 40px;
  .simple_ancer_small {
    background-color: transparent;
  }
  .simple_btn {
    padding-left: 0;
    padding-top: 0;
    width: max-content;
  }
  .row {
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    &:nth-child(1) {
      margin-top: 0px;
      padding-left: 0;
    }
    &:nth-child(2) {
      margin-top: 5px;
      padding-left: 10px;
    }
  }
}

.add_hospital {
  .ant-form {
    .column_flex {
      .row {
        &:nth-child(2) {
          padding-top: 0;
        }
        .leaflet-container {
          height: 192px;
          width: 100%;
          .leaflet-right {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .online_time {
    .addSlots {
      .simple_btn {
        padding: 0;
      }
    }
    .confirm_btn {
      .simple_btn {
        justify-content: center;
      }
    }
  }
  .health_wrapper {
    &.cover_space2 {
      padding-top: 0 !important;
    }
  }
  .clinics {
    .simple_btn {
      position: fixed;
      bottom: 40px;
      right: 0;
      z-index: 1000;
      padding-right: 0;
    }
  }
  .infoClinic {
    &.cover_space2 {
      padding-top: 0 !important;
    }
  }
}
