.Payment_history h1 {
    font-weight: 500;
    font-size: 26px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #313131;
    margin-bottom: 0px;

    svg {
        margin-right: 18px;

        @media (min-width:0px) and (max-width:767px) {
            margin-right: 11px;
            width: 21px;
            height: 21px;
            position: relative;
            top: -2px;
        }
    }

    @media (min-width:0px) and (max-width:767px) {
        font-size: 20px;
        line-height: 27px;
    }

}


// .ant-picker-range .ant-picker-suffix i {
//   content: "\E615";
// }

.marginTopSearchBar{
    margin-top: 28px;
}

.download_btn {
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    text-decoration-line: underline;
    color: #313131;
    margin-left: 33px;
    text-transform: uppercase;

    img {
        margin-right: 8px;
    }

    @media (min-width:1301px) and (max-width:1500px) {
        font-size: 12px;

        margin-left: 23px;

    }

}







@media (min-width:0px) and (max-width:767px) {
    .or3 {
        order: 3;
    }

    .or2 {
        order: 2;
    }

    .or1 {
        order: 1;
    }

    .or4 {
        order: 4;
    }

    .mobile_download span.download_btn {
        margin-left: 0;
        font-size: 11px;
    }

    .mobile_download {
        margin-top: 30px;
        margin-bottom: 22px;

        .download_btn {
            font-family: "Nunito";
            font-weight: 600;
            color: #313131;
            font-size: 11px;

        }
    }


    .pb-30 {
        padding-bottom: 16px !important;
    }





    // mobile table
    .mobile_record_list {

        display: flex;
        justify-content: space-between;
        margin: 0;
    }

    .mobile_record {
        margin-bottom: 9px;

        background: #FFFFFF;
        border-radius: 6px;
        padding: 12px 16px;
    }

    .mobile_record_list li {
        /* width: 33.333%; */
    }

    .mobile_record_list li p {
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
        color: #078A8E;
        height: 17px;
        margin-bottom: 7px;
    }

    .mobile_record_list li span {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        align-items: center;
        color: #313131;
        display: block;
    }

    ul.mobile_record_list li h6.price {
        background: rgba(59, 153, 21, 0.2);
        border-radius: 5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */
        color: #3B9915;
        display: inline-block;
        padding: 0px 4px;
    }

    ul.mobile_record_list li a {
        font-weight: 300;
        display: block;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */
        text-decoration-line: underline !important;
        color: #29BCC1 !important;
    }

    ul.mobile_record_list li:last-child {
        text-align: center;
    }

    .mobile_record_list {
        display: flex;
        justify-content: space-between;
        margin: 0;
    }

    .mobile_record {
        background: #FFFFFF;
        border-radius: 6px;
        padding: 12px 16px;
    }

    .mobile_record_list li {
        /* width: 33.333%; */
    }

    .mobile_record_list li p {
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
        color: #078A8E;
        height: 17px;
        margin-bottom: 7px;
    }

    .mobile_record_list li span {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        align-items: center;
        color: #313131;
        display: block;
    }

    ul.mobile_record_list li h6.price {
        background: rgba(59, 153, 21, 0.2);
        border-radius: 5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */
        color: #3B9915;
        display: inline-block;
        padding: 0px 4px;
    }

    ul.mobile_record_list li a {
        font-weight: 300;
        display: block;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */
        text-decoration-line: underline !important;
        color: #29BCC1 !important;
    }

    ul.mobile_record_list li:last-child {
        text-align: center;
    }

    .appointment_list_mobile {
        padding: 41px 17px;
    }

    .appointment_list_mobile ul.list_appoint_mob li {}

    .appointment_list_mobile ul.list_appoint_mob li p {
        font-size: 12px;
        line-height: 15px;
        color: #313131;
        font-weight: 300;
        margin-bottom: 3px;
    }

    .appointment_list_mobile ul.list_appoint_mob li p span {
        font-weight: 700;
    }

    .appointment_list_mobile ul.list_appoint_mob li h6.price {
        background: rgba(59, 153, 21, 0.2);
        border-radius: 5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height */
        color: #3B9915;
        padding: 2px 5px;
    }

    ul.list_appoint_mob {
        display: flex;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        padding: 10px;
    }




    .payment_history_modal .ant-modal-content .ant-modal-header {

        border-bottom: 0px;
        padding-bottom: 0px;
    }

    .payment_history_modal .ant-modal-title {

        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 22px !important;
    }

    .no_data_mobile {
        text-align: center;
        background: #fff;
        padding: 150px 0;
        margin-bottom: 10px;
        border-radius: 6px;
    }

    .no_data_mobile p {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */
        text-align: center;
        color: #959494;
    }


}