.change_password {
  .headingWithSpaceLarge {
    padding: 10px 0 30px;
  }
  .simple_btn_small {
    max-width: 354px;
    justify-content: center;
    margin: auto;
  }
}
