.addBankModal {
  .modal-content {
    border-radius: 23px;
    min-height: 400px;
    max-width: 823px;
    width: 100%;
  }
  .ant-form-item-explain .ant-form-item-explain-connected {
    min-height: 19px !important;
  }
  .ant-form-item-explain-error {
    text-align: left;
    color: #BC0001;
  }




  .modal-dialog {
    .ant-input-status-error {
      border-color: red !important;
    }


    // max-width: 823px;
    .modal-header {
      border-bottom: none;
      padding: 0;

      .btn-close {
        margin: 1rem 1.5rem -0.5rem auto;
      }
    }


    .modal-body {
      padding-top: 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      // padding: 0px 30px 15px;
      // padding: 0px 10px 15px;

      &.delete {
        align-items: center;
        padding-top: 30px;
        gap: 30px;

        .flex_center {
          gap: 20px;
          margin-top: 60px;
        }
      }

      .ant-form {
        width: 100%;
        padding-top: 30px;

        .input_box {
          background-color: #f7f7f7;

        }

        .row {
          padding-bottom: 10px;
          text-align: center;
        }

        .labelText {
          text-align: left;
        }

        .c_input {
          background-color: #f7f7f7;
        }

        .c_select {
          // background-color: #f7f7f7;
          z-index: 1000;
        }

        .ant-select-selector {
          background-color: #f7f7f7;
          text-align: left;
          padding: 0;
        }

        .simple_btn {
          max-width: 354px;
          margin-top: 50px;

          &:hover {
            border: 1px solid black;
            color: black;
            background-color: white !important;
          }
        }
      }
    }
  }
}

.ant-select-dropdown {
  z-index: 10000;
  border-radius: 12px;
  // --------for temporary
  // top: 766px !important;
}

.addBankModal .ant-select-selector .ant-select-selection-item {
  line-height: 42px;
}

@media screen and (max-width: 550px) {
  .modal-dialog {
    margin: 1.5rem;
  }
}

.addBankModal .modal-dialog .content .ant-form .ant-select-status-error .ant-select-selector,
.addBankModal .modal-dialog .content .ant-form .ant-input-status-error.c_input {
  border-color: #BC0001 !important;
}



.addBankModal input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.addBankModal input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.addBankModal .accountNumber input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}


.bankSubmitBtn {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #FFFFFF;

}

@media (min-width:0px) and (max-width:767px) {
  .addBankModal .modal-body {
    padding: 1rem 0;
  }
}