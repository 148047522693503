/* .earnings .headingWithSpaceLarge {
  color: black;
} */

.earnings {

  .accordian_earning_results .accordion {
    border-radius: 15px;
    background-color: #ffffff;
  }

  .earning_accordian .earningWhiteAccordian {
    padding: 20px;
    background-color: #ffffff;
  }
  
  .quickSummaryBox .card h6{
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #404040;
  }


 .white_color_div.no_bank button.addBankDetails {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #19B3B5;

  }

  .historyHeading h4 {


    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #404040;

  }

  // .date_filter .ant-picker.ant-picker-range .ant-picker-input input{
  //     font-family: 'Circular Std';
  // }

  .ant-table-cell{
      font-family: 'Circular Std';
      font-weight: 300;

  }

  .ant-table-tbody > tr > td:nth-child(6) {
    text-align: center;
    margin-right: 10px;
}

  .ant-table-tbody > tr > td:nth-child(7) {
    text-align: center;
}

  .col-lg-3 {
    // padding-left: 0;
  }

  .quickSummaryContainer {
    display: flex;
    flex-wrap: wrap;

    // width: 100%;
    // gap: 10px 15px;
    .quickSummaryBox {
      width: 100%;
    }

    .subtitle {
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #8E8E8E;

    }

    .card {
      background-color: var(--white-clr);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      min-height: 104px;
      padding: 20px;
      margin: 0;
      border-radius: 17px;
      border: none;
      max-height: 130px;
      >div {
        &:first-child {
          // width: 74%;
          width: 100%;
        }
      }
    }
  }

  #bank_details {

    // padding: 15px 0px 0 0px;
    // gap: 6px;
    .column_flex2 {
      width: 100%;
    }

    .column_flex {
      padding-bottom: 15px;

      .headingDescVsmall:nth-child(2) {
        // font-weight: 700;
        font-weight: 400;
      }

      .justify_between {
        width: 100%;

        .headingDescVsmall {
          font-weight: 700;
          word-wrap: break-word;
          width: 70%;
        }
      }

      .flex_center {
        gap: 10px;
      }
    }

    .pink_div {
      background-color: #ffd3cb;
      border-radius: 7px;
      padding: 5px;
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 50%;

      .heading_desc_small {
        font-weight: 700;
      }
    }
  }

  .headingDescVsmall {
    margin: 0;
    line-height: 17px;
    margin-bottom: 5px;
  }

  .heading_desc {
    font-size: 1.563rem;
    font-weight: 700;
    padding-bottom: 10px;
  }

  .quickSummaryBox {
    &:nth-child(1) {
      .card {
        .heading_desc {
          color: var(--rosepink-clr);
        }
      }
    }

    &:nth-child(2) {
      .card {
        .heading_desc {
          color: var(--green-clr);
        }
      }
    }

    &:nth-child(3) {
      .card {
        .heading_desc {
          color: var(--orange-clr);
        }
      }
    }

    &:nth-child(4) {
      .card {
        .heading_desc {
          color: var(--yellow-clr);
        }
      }
    }

    &:nth-child(5) {
      .card {
        .heading_desc {
          color: var(--tifannyblue-clr);
        }
      }
    }
  }

  .white_color_div {
    height: 100%;
    background-color: var(--white-clr);
    padding: 20px 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    &.no_bank {
      align-items: flex-start;
    }

    .headingWithSpaceLarge {
      margin-bottom: 10px;
      margin-top: 0;
    }

    .simple_btn_small {
      margin-top: 30px;
      width: 125px;
      justify-content: center;
    }
  }
}

.grey_div {
  background-color: #efefef;
  border-radius: 11px;
  padding: 5px 15px;
  gap: 10px;
}

.overflow_div {
  overflow: hidden;
}

.ant-table {
  overflow: auto;
}

.earningDashboardSet .ant-table {
  border-radius: 8px !important;

}

.earningDashboardSet .ant-table-container {
  border-radius: 8px !important;
}

// -----------------------
.historyPaymentBtn {
  background: #29BCC1;
  border-radius: 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #FFFFFF;
}

.earningHeadingTop h5 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 26px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #313131;
  text-transform: uppercase;
}

.videoCallBoxx .singleBoxCalls {
  border: 0.5px solid #CACACA;
  border-radius: 10px;
}

.appointments-slider .ant-table-thead>tr>th {
  text-align: center;
}

.earningDashboardSet .ant-row.ant-form-item {
  margin-bottom: 0;
}

.earningDashboardSet .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
}

.earningDashboardSet .c_select {
  min-height: auto;
}

@media screen and (max-width: 660px) {
  .earnings {
    &.cover_space {
      padding-top: 0 !important;

      .reviews {
        padding-top: 0px;

        .single_reviews {
          margin-bottom: 10px;

          .right_arrow {
            background: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .earnings {
    .col-lg-3 {
      // padding-left: 10px;
    }

    .quickSummaryContainer {
      .quickSummaryBox {
        width: 100%;

        .card {
          min-height: auto;
          padding: 15px 15px 18px;
          border-radius: 10px;

          .flex_start {
            padding-top: 0;
            padding-bottom: 0;
            // align-items: center;
            align-items: flex-start;
            justify-content: space-between;
            width: 100% !important;

            .heading_desc {
              padding-bottom: 0;
            }
            .heading_desc_small{
              margin-top: 5px;
            }
          }
        }
      }
    }

    .white_color_div {
      align-items: center;
    }

    #bank_details {
      padding: 0 10px;
      margin-top: 10px;

      .white_color_div {
        padding: 20px 20px 10px;
      }

      .column_flex {
        .justify_between {
          .headingDescVsmall {
            width: 90%;
          }
        }

        .flex_center {
          flex-direction: column;
          align-items: flex-start;

          .flex_center {
            flex-direction: row;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .earnings {
    #bank_details {
      .column_flex {
        .flex_center {
          flex-direction: row;
        }
      }
    }
  }
}


.earningHeadingTop a.simple_ancer_small {
  background: #29BCC1;
  border-radius: 8px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #FFFFFF;
}




.belowApointmentsCards{
  font-family: 'Circular Std';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 23px;
display: flex;
align-items: center;
color: #404040;

}

.Removefilters p {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #EF6286;
  cursor: pointer;
}

.dateTimeCalen .ant-picker-suffix {
  display: none;
}

.headingSameAll {
  font-size: 14px;
  line-height: 20px;

  color: #535151;
}

.EarningsTitles{
  max-width: 200px;
  line-height: 30px;
  word-break: break-word;
}

.earningsFontFamily {
  font-family: 'Circular Std';
}

.appointmentTypeSelect {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  display: flex;
  align-items: center;
}

.appointmentsEarns{
  font-family: 'Circular Std';
font-style: normal;
font-weight: 400 !important;
font-size: 18px;
line-height: 23px;
display: flex;
align-items: center;
color: #404040;
}

.appointmentTypeSelect .ant-select-selector {
  border: 0.3px solid rgba(149, 148, 148, 0.368627451) !important;
  border-radius: 6px !important;
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 10px !important;
}

.appointmentTypeSelect .ant-select-selection-item {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #313131;
  display: flex;
  align-items: center;
}

.downloadBtnBarr {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #313131;
}

.searchBarButton {
  margin-bottom: 0 !important;
  font-size: 12px !important;
  z-index: 8 !important;
}

.searchBarButton .searchField {
  font-family: 'Circular Std';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 20px;
color: #959494;

}

.downloadBtnBarr {
  font-size: 12px !important;
}

.paddingSameLR {}

.ant-select-item-option-content,
.searchBarButton .input-group input {
  font-size: 12px !important;
}

#earnings .ant-table-tbody>tr>td {
  padding: 16px;
}


.NoEarnings {
  height: 156px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.25rem;
  border-radius: 6px;
  background-color: #fff;
}

.NoEarnings p {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #959494;
  }

.w-70 {
  width: 70%;
}

.table_border {
  border-radius: 10px;
  overflow: hidden;
}

.table_border {
  border-radius: 10px;
  overflow: hidden;
}

.table_border .ant-pagination-item-active {
  background: #29BCC1;
  border-radius: 50%;
  color: #fff;
}

.table_border .ant-pagination-item {
  width: 36px;
  line-height: 36px;
  height: 36px;
}

.earning_toast .Toastify__toast-theme--dark {
  background: #FFFFFF;
  border: 1px solid #19B3B5;
  color: #19B3B5;
  // min-width: 310px;
  min-height: 30px;
  border-radius: 6px;
}

.earning_toast .Toastify__toast-theme--dark .Toastify__toast-body {
  padding: 0;
}

.earning_toast.toast_success .Toastify__toast-container.Toastify__toast-container--top-center {
  min-width: 310px;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
  top: 10px;
}

.earning_toast .Toastify__toast-theme--dark .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter svg {
  fill: #19B3B5;
}
.bottomRsFixess .card h3 {
    margin-top: 0;
    bottom: 8px;
    position: absolute;
    right: 20px;
}
div#earnings .date_filter .ant-picker-input {
  width: 170px;

  @media (min-width:1201px) and (max-width:1780px) {
    width: 100%;
  }

}
@media only screen and (min-width: 1300px){
  .earnings .quickSummaryContainer .card {
     min-height: 130px;
  }
}
@media (min-width:0px) and (max-width:767px) {
  .cover_space {
    padding-left: 0;
    padding-right: 0;
  }

  .singleBoxCalls h3,
  .quickSummaryBox h3 {
    font-weight: 450;
  }

  .earnings .ant-picker-range .DateInput_input::placeholder{
    color: red;
  }

  .earnings .appointment_mobile .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px !important;
  }

  .earnings .quickSummaryBox .card h6{
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 28px;
    text-align: right;
    color: #404040;
  }

  .earnings .historyHeading h4{
    font-size: 18px;

  }

}