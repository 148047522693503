.dashboardNew .imgProfile {
    width: 100px;
    height: 100px;
}

.dashboardNew h4 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 28px;
    line-height: 35px;
    display: flex;
    align-items: center;
    font-variant: none !important;
    font-feature-settings: "smcp" 1 !important;

}

.dashboardNew p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 300;
    font-size: 15.2px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #404040;
}

.dashboardNew .progress {
    background-color: #D9D9D9;
    border-radius: 10px;
}

.dashboardNew .progress-bar {
    background-color: #29BCC1;
    border-radius: 10px;
}

.dashboardNew .percentageBar h6 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #313131;
    margin-top: 10px;
}

.dashboardNew .percentageBar a {
    border: 1px solid #DC3545;
    border-radius: 10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 15px auto 0;
    color: #DC3545;
    padding: 12px 15px;
    display: inline-block;
}

.dashboardNew .statusHead h3 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    display: flex;
    align-items: center;

    color: #000000;
}

.dashboardNew .statusHead .status {
    background: rgba(220, 53, 69, 0.2);
    border-radius: 3px;
}

.dashboardNew .statusHead .status p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;

    color: #DC3545;
}

.completionBoxShad {
    background: #FFFFFF;
    box-shadow: 0px -3.74468px 13.1064px rgba(0, 0, 0, 0.05);
    border-radius: 18.7234px;
}

.completionBoxShad h3 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    color: #000000;
}

.completionBoxShad p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #313131;
}

.completionBoxShad .accordion-button:not(.collapsed) {
    border-bottom: 1px solid #D9D9D9 !important;
}

.completionBoxShad .accordion-button {
    padding: 1rem !important;
}

.completionBoxShad .accordionInner h3 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #313131;
}

.completionBoxShad .accordionInner p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #313131;
}

.beforeImgAdd:after {
    content: '';
    width: 1px;
    height: 45px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width=%272%27 height=%2734%27 viewBox=%270 0 2 34%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M1 33L1 1%27 stroke=%27%23D9D9D9%27 stroke-width=%270.75%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-dasharray=%270.05 4%27/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 30px;
    left: 39px;
    background-size: contain;
    border: 0.75px dashed #D9D9D9;
}

.pendingLabell.status {
    background: rgba(235, 142, 57, 0.2);
    border-radius: 4.23529px;
    border: 0;
}

.pendingLabell.status p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #EB8E39;
}

.dashboardTermsModal .introHeaad {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    color: #313131;
}

.dashboardTermsModal p,
.dashboardTermsModal ul li {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 21px;
    color: #404040;
}

.checkBoxAgreee .ant-checkbox .ant-checkbox-inner {
    border: 0.5px solid rgba(217, 217, 217, 0.98);
    border-radius: 3px;
    width: 18px;
    height: 18px;
}

.checkBoxAgreee span {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 21px !important;
    color: #404040;
    padding-top: 0 !important;
    top: 0;
}

.checkBoxAgreee.ant-checkbox-wrapper {
    background-color: transparent;
}

.checkBoxAgreee .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7CC14B;
}

.checkBoxAgreee .ant-checkbox-checked .ant-checkbox-inner::after {
    top: 44%;
    left: 20%;
    width: 5px;
    height: 12px;
    font-weight: 800;
}

.dashboardTermsModal .modal-footer button {

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.dashboardTermsModal .modal-footer button:disabled,
.dashboardTermsModal .modal-footer button[disabled] {
    background-color: #E6E6E6;
    color: white;
    border-color: #E6E6E6 !important;
    opacity: 1;
}
.dashboardTermsModal .modal-body {
    max-height: 480px;
    overflow: auto;
}

.dashboardTermsModal .modal-body::-webkit-scrollbar {
    width: 10px;
}

.dashboardTermsModal .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    background-color: #E2E2E2;
    border-radius: 5px;
}

.dashboardTermsModal .modal-body::-webkit-scrollbar-thumb {
    background: #B9B9B9;
    border-radius: 5px;


}

.img-width-sett {
    background: #E1F3F4;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.belowSpeciality{
    font-size: 19px;
    font-family: Circular Std;
    font-weight: 300;
    
}

@media (min-width: 992px) {
    .dashboardTermsModal .modal-dialog {
        max-width: 800px;
    }

    .dashboardNew .progress-bar {
        font-size: 0;
    }
}

@media (min-width: 1200px) {
    .dashboardTermsModal .modal-dialog {
        max-width: 1140px;
    }
}

@media only screen and (max-width: 991px) {
    .dashboardNew {
        background-color: white;
        padding-left: 15px;
        padding-right: 15px;
    }

    .dashboardNew .bg-change-mobile {
        background-color: #E1F3F4 !important;
    }

    .dashboardNew .imgProfile {
        width: auto;
        height: auto;
    }

    .dashboardNew h4 {
        font-size: 18px;
        line-height: 23px;
    }

    .dashboardNew .percentageBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }

    .dashboardNew .percentageBar h6 {
        margin-top: 5px;
    }

    .dashboardNew .percentageBar button {
        background: #FFFFFF;
        border: 1px solid #19B3B5;
        border-radius: 10px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #19B3B5;
        margin-top: 0;
        margin-right: 0;
        padding: 7px 15px;
    }

    .dashboardNew .statusHead h3 {
        font-size: 20px;
        line-height: 27px;
    }

    .dashboardNew .statusHead .status p {
        font-size: 14px;
        line-height: 18px;
    }

    .completionBoxShad h3,
    .completionBoxShad .accordionInner h3 {
        font-size: 14px;
        line-height: 19px;
    }

    .completionBoxShad p,
    .completionBoxShad .accordionInner p {
        font-size: 10px;
        line-height: 14px;
    }

    .completionBoxShad .accordion-button,
    .completionBoxShad .accordion-button:focus,
    .completionBoxShad.mt-4 {
        padding: 0.5rem !important;
        background: #E1F3F4;
        box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.05);
        border-radius: 10px !important;
    }

    .completionBoxShad .accordion-button:not(.collapsed) {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .img-width-sett {
        background: #fff;
        width: 35px;
        height: 35px;
    }
}