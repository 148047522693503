.header {
    position: relative;
    
    .navbar-light {
        .navbar-nav {
            a{
                &:hover {
                    border-bottom: none;
                    margin-bottom: 20px;
                }
                .btn-outline-dark {
                    border-radius: 10px;
                    padding: 6px 20px;
                    font-size: 14px;
                    font-weight: 700;
                    letter-spacing: 1.4px;
                    &:hover {
                        transform: scale(0.96);
                        transition: 0.5s ease-in-out;
                        background-color: transparent;
                        color: black;
                    }
                }
            }
        } 
    }
}

@media screen and (max-width: 1100px) {
    .header {
        display: none
    }
}