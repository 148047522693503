.simple_ancer_small {
  display: flex;
  align-items: center;
  color: var(--white-clr);
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 1.2px;
  border-radius: 16px;
  min-height: 51px;
  position: relative;
  padding: 10px 20px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  background-color: var(--green-clr);
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

  &:hover {
    color: var(--white-clr);
  }
}
