/* ANIMATED Toggler */
/* ANIMATED X */
/* ANIMATED X COLLAPSED */
/* END ANIMATED X */
#navbar {
  position: relative;

  .navbar-nav {
    gap: 20px;
    padding-right: 40px;
    margin-left: 0 !important;

    .linkactive {
      border-bottom: 5px solid #19B3B5;
      margin-bottom: 0px;
    }

    .nav-item.dropdown {
      li {
        margin-left: 10px;
      }

      a {
        color: #100f1d;
        font-size: 0.9rem;
      }
    }

    .dropdown {
      &:hover {
        >a {
          color: var(--tifannyblue-clr);
        }
      }
    }
  }

  .navbar {
    .section-heading {
      border-bottom: 0.30000001192092896px solid #70707050;

      h2 {
        font-size: 1.1rem;
      }
    }
  }

  .navbar-toggler-icon {
    height: 20px;
    width: 20px;
  }

  .nav-item.dropdown {
    position: unset;
    margin: 1px 20px;
    transition: all 0.5s ease-in-out;

    &:hover {
      .dropdown-toggle {
        &::after {
          border: solid var(--tifannyblue-clr) !important;
          border-width: 0 1.5px 1.5px 0 !important;
        }
      }

      .dropdown-menu {
        visibility: visible !important;
        display: block;
        opacity: 1;
        transform: translateY(0%);
        z-index: 4;
      }

      &::after {
        bottom: -5px;
        visibility: visible;
        opacity: 1;
      }
    }

    .dropdown-menu {
      visibility: hidden !important;
      opacity: 0;
      display: block !important;
      width: 100vw;
      background-color: #fdecf1;
      padding: 20px 0;
      box-shadow: 0px 6px 12px #00000029;
      border-radius: 20px;
      border: none;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      transform: translateY(-1em);
      z-index: -1;
      margin-top: -1px;

      .btn_icon_box {
        img {
          margin-right: 15px;
        }
      }

      .mega_menu {
        padding-left: 1rem;

        a {
          font-size: 0.75rem;
          font-weight: 600;
          color: var(--text-clr);
          line-height: 16px;
          position: relative;

          &::after {
            top: 17px;
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            content: "";
            display: block;
            height: 1px;
            position: absolute;
            background: var(--text-clr);
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
            left: 50%;
          }

          &:hover {
            &::after {
              left: -1px;
              width: 100%;
            }
          }
        }
      }
    }

    &::after {
      content: "";
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s ease-in, 0.2s ease-out;
      bottom: -10px;
      border-bottom: 17px solid #fdecf1;
      border-right: 19px solid transparent;
      border-left: 19px solid transparent;
      margin-left: 40px;
      position: absolute;
      z-index: 8;
    }
  }

  #megaMenuDropDown.dropdown-toggle {
    &::after {
      border: solid #100f1d;
      border-width: 0 1.5px 1.5px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-left: 10px;
    }
  }

  #megaMenuDropDown {
    &:hover {
      .dropdown-toggle {
        &::after {
          color: var(--tifannyblue-clr);
        }
      }
    }
  }

  .nav-item.dropdown.show {
    .dropdown-menu.show {
      display: block !important;
    }
  }

  .simple_btn {
    color: var(--text-clr);
    font-size: 0.875rem;
    text-transform: uppercase;
    border: 1px solid var(--text-clr);
    padding: 10px 30px;
    min-height: 41px;
    border-radius: 10px;
    margin-left: 20px;
    width: fit-content;
  }

  .search-wrapper {
    .input-holder {
      border-radius: 100px;

      .search-icon {
        border-radius: 100px;
      }

      .search-input {
        border-radius: 50px;
      }
    }

    border-radius: 100px;
  }
}

.orange-bg {
  background-color: orange;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  padding: 8px 13px;
  margin-right: 10px;
}

.orange-bg.bg-gray {
  background-color: #F4F4F4;
}

.stickyNavbar {
  transition: all 0.5s ease-in-out;
  position: fixed;
  padding: 0px 50px;
  background-color: white;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all 0.5s ease-in-out;
  // box-shadow: 0px -1px 12px grey;
}

.active2 {
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029;
  transform: translateY(0px) !important;
  // padding: 20px 0;
  position: fixed;
}

.navbar-light {
  .navbar-nav {
    >a {
      color: #100f1d;
      margin: 25px 0px 20px;

      h5 {
        font-weight: 600 !important;
        font-family: 'Nunito', sans-serif;
      }

      &:active {
        border-bottom: 5px solid #19B3B5;
        margin-bottom: 0px;
      }

      &:hover {
        border-bottom: 5px solid #19B3B5;
        margin-bottom: 0px;
      }
    }
  }

  .navbar-toggler {
    height: 35px;
    margin-top: 10px;
  }
}

.navbar-toggler {
  &:focus {
    box-shadow: 0 0 0 0;
  }
}

.x.navbar-toggle {
  .icon-bar {
    display: block;
    width: 18px;
    height: 2px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background-color: #100f1d;
  }
}

.navbar-toggle.x {
  .icon-bar {
    &:nth-of-type(1) {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transform-origin: 30% 10%;
      -ms-transform-origin: 30% 10%;
      transform-origin: 30% 10%;
    }

    &:nth-of-type(2) {
      opacity: 0;
      filter: alpha(opacity=0);
    }

    &:nth-of-type(3) {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: 30% 90%;
      -ms-transform-origin: 30% 90%;
      transform-origin: 30% 90%;
    }

    margin-right: -4px !important;
  }
}

.navbar-toggle.x.collapsed {
  .icon-bar {
    &:nth-of-type(1) {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
    }

    &:nth-of-type(2) {
      opacity: 1;
      filter: alpha(opacity=100);
      margin: 3px 0;
    }

    &:nth-of-type(3) {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
    }

    margin-left: -2px !important;
    margin-right: -2px !important;
  }
}

@media screen and (max-width: 1000px) {
  .stickyNavbar {
    padding: 10px 0 0;
  }

  #navbar {
    .navbar-nav {
      gap: 0;
      padding-right: 0;
      padding-left: 10px;

      a {
        margin: 25px 10px 20px;
      }
    }

    .orange-bg {
      margin-left: 0px;
    }

    #loginDropdown {
      .dropdown-toggle {
        margin-left: 10px;
        padding-top: 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .stickyNavbar {
    padding: 10px 0 15px;
  }

  .orange-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    margin-top: 8px;
    margin-left: -5px;
  }

  .sidebar_image {
    width: 33px;
    height: 33px;
  }
}







.offcanvas.show {
  width: 80%;
}

#navbar .navbar-nav .linkactive h5 {
  color: #19B3B5;
}

.lastLinkColor h5 {
  color: #EF6286 !important;
}

.lastLinkColor.linkactive {
  border-color: #EF6286 !important;
}


div#loginDropdown .active .arrowIcon {
  transform: rotate(180deg);
  transition: .80s ease;
}

div#loginDropdown .arrowIcon {
  transition: .80s ease;
}