.update_profile {
  .headingWithSpaceLarge {
    padding: 10px 0 20px;
  }
  .doc_name_div {
    border-radius: 50%;
    background-color: #ef6286;
    width: 85px;
    height: 85px;
    margin: 0px 0 20px;
    position: relative;
    .doc_name {
      font-size: 2.188rem;
      font-weight: 700;
      color: var(--white-clr);
    }
    .editProfileIcon {
      position: absolute;
      right: 5px;
      bottom: 0px;
    }
  }

  .UpdateProfileSection {
    padding: 20px 0px 5px;
    border-top: 1px solid #c2c2c2;
    &:nth-child(6) {
      border-bottom: none;
    }

    .ant-checkbox-wrapper {
      background-color: transparent;
      padding-right: 0;
      .ant-checkbox-inner {
        border-color: transparent;
      }
    }
    .flex_start {
      gap: 10px;
      .ant-form-item {
        padding-bottom: 24px;
      }
    }
    .column_flex {
      .ant-form-item {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .flex_end {
        width: 100%;
      }
    }
    .simple_btn {
      width: auto;
      padding: 0;
      .flex_start {
        gap: 20px;
        .heading_desc_small {
          font-weight: 600;
          // color: #ef6286;
          color: black;
          letter-spacing: 0;
        }
      }
    }
    textarea {
      resize: none;
      padding: 10px 20px;
    }
    .ant-select-selection-item {
      font-size: 1rem;
    }
    .input_box {
      position: relative;
      margin-top: 30px;
      min-height: 55px;
      span {
        font-size: 1rem;
        padding-bottom: 2px;
      }
    }
    .phone_num {
      position: absolute;
      top: -30px;
      .ant-form-item-control {
        left: 45px;
      }
    }
    .ant-picker-disabled {
      background-color: #c2c2c2;
    }
    .services {
      padding: 55px 0px 20px;
      background-color: var(--white-clr);
      border-radius: 10px;
      .ant-checkbox-wrapper {
        padding: 0;
        gap: 10px;
        span {
          font-size: 1rem;
          font-weight: 400;
        }
        .ant-checkbox-inner {
          width: 18px;
          height: 18px;
          background-color: #f7f7f7;
          &::after {
            width: 5px;
            height: 10px;
            left: 28%;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #72d54a;
          }
        }
      }
      .checkbox_container {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #c2c2c2;
        padding: 10px 20px 0;
        gap: 3px;
        .ant-checkbox-wrapper {
          margin-left: 0;
        }
      }
    }
  }
  .simple_btn_small {
    max-width: 354px;
    justify-content: center;
    margin: 30px auto;
  }
  .ant-picker-suffix {
    display: none;
  }
}
.speciality {
  .ant-select-selector {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .update_profile {
    .doc_name_div {
      margin: 0px 0 20px;
    }
    .UpdateProfileSection {
      .flex_start {
        .column_flex {
          width: 100%;
        }
        .ant-form-item {
          width: 100%;
          padding-bottom: 0;
        }
        // flex-wrap: wrap;
        width: 100%;
      }
      .col-md-2 {
        .ant-form-item {
          margin-bottom: 24px;
        }
      }
      .input_box {
        margin-bottom: 24px;
      }
      .row {
        .col-md-4 {
          .row {
            .col-md-4 {
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 660px) {
  .update_profile {
    &.cover_space {
      padding-top: 0 !important;
    }
    .doc_name_div {
      margin: 20px 0;
    }
    .UpdateProfileSection {
      border-top: none;
      margin-bottom: 60px;
      padding-top: 0;
      .flex_start {
        flex-wrap: wrap;
      }
    }
    .updateButton {
      position: fixed;
      bottom: 0;
      left: 0;
      background: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .simple_btn_small {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
