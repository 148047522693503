.pt-121 {
    padding-top: 121px;
}

.mb-45 {
    margin-bottom: 45px;
    ;
}

.profile_update h2 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    /* identical to box height */
    color: #313131;
}

.video_consult input#consultation_fee {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    height: 46px;
}

.main_step_box .selectBox input#basic_city_id {
    padding-top: 20px;
}

.profile_update h2 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    /* identical to box height */
    color: #313131;
}

.user_name label#file_uploader {
    opacity: 0;
}

.user_name {
    border-radius: 50%;
    width: 85px;
    height: 85px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 35px;
    color: #FFFFFF;
    position: relative;
    line-height: 85px;
    text-align: center;

    button {
        position: absolute;
        right: -2px;
        bottom: 0;
        display: inline;
        line-height: 12px;
    }
}








.profile_tabs {}

.profile_tabs ul.nav.nav-tabs {
    margin: 0 !important;
    border: 0px;

    li.nav-item {
        margin-right: 10px;

        button {
            background: #F7F7F7;
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 44px;
            /* identical to box height */
            text-align: center;
            text-transform: uppercase;
            color: #646464;
            width: 260px;
            height: 44px;
            border-radius: 16px 16px 0 0;
            border: 0px;
            padding: 0;
        }

        button.nav-link.active {
            background: #FFFFFF;
        }
    }

}





.profile_tabs .tab-content {
    background: #fff;
    padding: 23px 25px;
    border-radius: 10px 10px 10px 10px;
}

.heading_profile {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #313131;
    position: relative;
    display: inline-block;
    padding: 0 15px 18px 15px;

    &:before {
        content: '';
        background: #313131;
        border-radius: 2px 2px 0px 0px;
        width: 100%;
        height: 3px;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.mb-74 {
    margin-bottom: 74px;

}

.mt-26 {
    margin-top: 26px;
    ;
}

.update_btn {
    background: #29BCC1;
    border-radius: 10px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 48px;
    /* identical to box height */
    height: 48px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    padding: 0;
    width: 169px;

    &:hover {
        background: #29BCC1;
        color: #FFFFFF;
    }
}





.position-desk {
    position: relative;
    top: 23px;
}









@media (min-width:0px) and (max-width:767px) {
    .mobile_center {
        text-align: center;
    }

    .pt-121 {
        padding-top: 15px;
    }

    .profile_update h2 {
        display: none;
    }

    .user_name {
        margin: 0 auto 20px auto;
        width: 62px;
        // height: 62px;
        height: auto;
        // line-height: 62px;
        line-height: 100%;
        font-weight: 450;
        font-size: 25.7471px;
    }

    .user_name img {
        width: 16px;
    }

    .user_name button {
        right: 0px;
    }


    .mobile-space0 {
        padding: 0;
    }

    .mobile-space0 .tab-content {
        margin: 0 15px;
        border-radius: 10px;
    }

    .profile_tabs .tab-content {
        padding: 19px 10px;
    }

    .mobile-space0 {
        padding: 0;
    }



    .profile_tabs ul.nav.nav-tabs li.nav-item button {
        height: auto;
        background: initial;
        border-bottom: 0.5px solid #8C8C8C;
        width: auto;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        padding: 0 20px 12px 20px;
        color: #8C8C8C;
    }

    .profile_tabs ul.nav.nav-tabs {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
    }

    .profile_tabs ul.nav.nav-tabs li.nav-item {
        padding-bottom: 29px;
        /* margin: 0 20px; */
        display: inline-block;
    }

    .profile_tabs ul.nav.nav-tabs li.nav-item:last-child button {
        width: 206px;
    }

    .profile_tabs ul.nav.nav-tabs li.nav-item button.nav-link.active {
        background: #ffffff00;
        color: #EF6286;
        border-bottom: 1px solid;
    }

    .profile_tabs ul.nav.nav-tabs li.nav-item {
        margin-right: 0;
    }

    .heading_profile {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        padding: 0;
        margin-bottom: 20px !important;
    }

    .heading_profile:before {
        display: none;
    }

    .profile_tabs .upload_button h5 {
        margin-top: 0px;
    }

    .profile_tabs .upload_button p {
        margin-bottom: 25px;
    }

    .profile_tabs .upload_button h5 .right_icon {

        top: -5px;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    .btn-top-position .add-btn.btn {
        bottom: -19px;
    }

    .profile_tabs .upload_button .ant-row.ant-form-item {
        margin-bottom: 0;
    }


    .condition_box label .popover_box1 {
        display: none;
    }


    .selectBox.condition_box label .popover_box1 {
        display: inline-block;
    }

    .mb-19 {
        margin-bottom: 19px;

    }

    .delete {
        width: 26px;
        margin-bottom: 5px;
    }

    .mobile_center.text-end {
        text-align: center !important;
    }

    .mobor1 {
        order: 1;
    }

    .mobor2 {
        order: 2;
    }

    .mobor3 {
        order: 3;
    }

    .mobor4 {
        order: 4;
    }

    .checkbox_style.consultation_checkbox {
        margin-bottom: 4px;
    }

    .btn.btn_add.btn-dark {
        border: 1px solid #19B3B5;
        background: #fff;
        color: #19B3B5;
        position: absolute;
        right: 0;
        bottom: -12px;
    }



    .profile_tabs ul.nav.nav-tabs::-webkit-scrollbar {
        width: 0em;
        height: 0px;
    }

    .profile_tabs ul.nav.nav-tabs::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .profile_tabs ul.nav.nav-tabs::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    .toast_blue .Toastify__toast-container .Toastify__toast--success .Toastify__toast-body {

        font-size: 12px;
        line-height: 140%;
        padding: 0 20px;
    }

    .toast_blue .Toastify__toast-container {

        min-width: 300px;
        width: 300px;
        top: 40px;
    }
}

.video_consult .add_more {
    position: relative;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: right;
}

.modalTakePhotoPrev .ant-modal-content {
    box-shadow: none;
    border-radius: 15px;
    background-color: #313131;
}

.modal-xl.modalTakePhotoPrev {
    min-width: 85%;

}

.modalTakePhotoPrev .ant-modal-footer {
    border-top-color: transparent;
}

.modalTakePhotoPrev .primaryBtnBg {
    background-color: #29BCC1;
    border-radius: 9px;
    padding: 20px 40px;
    display: inline-flex;
    align-items: center;
    color: #fff;
}

.modalTakePhotoPrev .btnRetake {
    border-radius: 9px;
    padding: 20px 40px;
    display: inline-flex;
    align-items: center;
}

.modalTakePhotoPrev button span {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-align: center;
}

.unableClickView {
    border-radius: 6px;
    border: 0.5px solid #B29E0F;
    background: rgba(255, 232, 71, 0.20);
    padding: 7px 12px;
    margin-bottom: 15px;

}

.unableClickView span {
    font-family: Circular Std;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;

    color: #B29E0F;
}