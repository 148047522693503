.simple_btn {
  color: var(--white-clr);
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1.6px;
  border-radius: 16px;
  min-height: 62px;
  position: relative;
  padding: 10px 50px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  width: 100%; 
  &:hover {
    transform: scale(0.95);
    transition: 0.5s ease-in-out;
  }
}
