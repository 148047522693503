.stickyMenu {
  padding-top: 0px;
  // min-height: 62px;
  background-color: #ffffff;
  opacity: 1;
  transform: translateY(0px);
  left: 0;
  width: 100%;
  z-index: 44444;
  z-index: 1 !important;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;

  .navbar {
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .arrowBtn.left {
    margin-right: 10px;
  }

  .arrowBtn.right {
    margin-left: 10px;
  }

  .navbar-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow: auto;

    li {
      a {
        display: block;
        margin: 0px 40px;
        font-size: 0.875rem;
        font-family: "Nunito", sans-serif;
        min-width: max-content;
        max-width: max-content;
        padding-bottom: 15px;
        color: #9d9d9d;

        &.linkactive {
          color: #ef6286;
          border-bottom: 5px solid #ef6286;
          padding-bottom: 10px;
        }

        &:hover {
          color: #ef6286;
          border-bottom: 5px solid #ef6286;
          padding-bottom: 10px;
        }

        &:first-child {
          margin-left: 0;
        }

        &.activeLink {
          color: #ef6286;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.active1 {
  opacity: 1;
  top: 67px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 3px 6px #00000029;
  position: fixed;
}

@media screen and (max-width: 660px) {
  .stickyMenu {
    box-shadow: none;
    padding-top: 10px !important;

    .navbar-nav {
      flex-direction: row;
    }
  }
}