.MedBtn-btn:hover {
  color: white !important;
  text-decoration: none;
}

.forParentMed input {
  width: 100%;
}

.newPrescriptionChecks input {
  background-color: #fff;
  border: 1px solid #d0d0d0 !important;
  border-radius: 12px;
  line-height: 56px;
  margin-top: 6px;
  padding-left: 5px;
  height: 54px;
  color: #959494;
font-family: "Circular Std";
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: normal;
margin-top: 0!important;
}

.add-custom-med {
  background-color: #19B3B5;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer !important;
}