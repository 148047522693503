.pt-120 {
    padding-top: 97px;
}

.complate_profile {
    padding-left: 7px;

    .number_complition {
        width: 60px;
        height: 60px;
        // border: 2px solid #19B3B5;
        line-height: 60px;
        text-align: center;
        border-radius: 50%;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 500;
        position: relative;
        font-size: 12px;
        color: #000000;
    }

    h6 {
        font-family: 'Nunito';
        font-style: normal;
        margin-left: 18px;
        font-weight: 500;
        font-size: 23.2px;
        line-height: 140.4%;
        /* identical to box height, or 33px */
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #000000;
        // padding-left: 18px;
    }
}


.circle_box {
    position: relative;
    width: 60px;
    height: 60px;

    img {
        position: absolute;
    }
}



.pt-40 {
    padding-top: 28px;
}

.pb-50 {
    padding-bottom: 50px;
}

.progressbar {
    position: relative;
    z-index: 9;
    background: #F2F2F2;
    /* height: calc(100vh - 101px); */
    height: 100vh;
}

.progressbar:before {
    content: '';
    width: 25%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #CFECED;
    z-index: -9;
    // z-index: 1;
}

// .z-index-relative {
//     z-index: 99;
//     position: relative;
// }









.top_pagination {
    text-align: center;
    padding-top: 15px;

    ul {
        list-style: none;
        justify-content: space-around;
        display: flex;
        padding: 0;
        margin: 0 100px 11px;

        li {
            width: 25%;
            text-align: center;
            position: relative;


            h4 {
                font-family: "Nunito", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                /* identical to box height */
                color: #000000;
                margin-top: 5px;
            }

            p {
                font-family: 'Circular Std';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 17px;
                text-align: center;
                color: #313131;
            }

            .box_image {
                width: 68px;
                height: 68px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
            }
        }
    }

}


.top_pagination ul li:before {
    content: '';
    width: 129px;
    height: 1px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='163' height='1' viewBox='0 0 163 1' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.5 0.5H162.5' stroke='black' stroke-dasharray='7 7'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 34px;
    right: 0;
    transform: translateX(50%);
    background-size: contain;
}

.top_pagination ul li:last-child:before {
    display: none;
}

.custom-progress .ant-progress-circle-path {
    stroke-width: 10px;
    /* Adjust the stroke width to change the thickness */
}


.progressUpperBar .ant-progress {
    background-color: #CFECED;
    border-radius: 100%;
}

.progressUpperBar .MuiCircularProgress-svg .css-1idz92c-MuiCircularProgress-svg {
    color: #29BCC1 !important;
    background-color: #CFECED;
}

.ant-picker-cell {
  color:   rgba(0, 0, 0, 0.85);
}