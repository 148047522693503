.ant-checkbox-wrapper {
  align-items: center;
  background-color: #ebebeb;
  border-radius: 7px;
  padding: 7px 25px 13px 15px;
  gap: 5px;

  span {
    &:nth-child(2) {
      padding-top: 5px;
      font-size: 0.875rem;
      font-weight: 700;
    }
  }
}
.ant-checkbox-checked {
  &::after {
    border: none;
  }
  .ant-checkbox-inner {
    background-color: #72d54a;
    &::after {
      top: 46%;
      left: 26%;
      width: 7px;
      height: 14px;
    }
  }
}
.ant-checkbox {
  border: none;
  border-radius: 50%;

  &:hover {
    background-color: #72d54a;
  }
  .ant-checkbox-inner {
    border: none;
    border-radius: 50%;
    width: 26px;
    height: 26px;
  }
}
